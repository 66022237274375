import { StaticImport } from 'next/dist/shared/lib/get-img-props';
import ImageComponent from '@/components/UI/image';
import classNames from 'classnames';

import style from './card.module.scss';

type Props = {
  icon: StaticImport | string;
  alt: string;
  text: string;
  otherStyleImage?: string;
  otherStyleText?: string;
  otherStyle?: string;
};

const Card: React.FC<Props> = ({
  icon = '',
  alt,
  text,
  otherStyleImage,
  otherStyleText,
  otherStyle,
}) => {
  const renderImage = () =>
    icon && (
      <ImageComponent image={icon} alt={alt} otherStyle={otherStyleImage} />
    );

  const renderText = () =>
    text && (
      <span
        className={classNames(style.card_text, {
          [`${otherStyleText}`]: otherStyleText,
        })}
      >
        {text}
      </span>
    );

  return (
    <div
      className={classNames(style.content, {
        [`${otherStyle}`]: otherStyle,
      })}
    >
      {renderImage()}
      {renderText()}
    </div>
  );
};

export default Card;
