import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Card from '@/components/UI/card';
import CustomLink from '@/components/UI/link';

import logo from '@/assets/icons/eateryClubLogo.png';

import style from './trafficPlan.module.scss';

const TrafficPlan = () => {
  const { t } = useTranslation();

  return (
    <section className={style.traffic}>
      <h2 className={style.traffic_title}>{t('price.title')}</h2>
      <div className={style.traffic_content}>
        <div className={classNames(style.traffic_content_block, style.left)}>
          <h3 className={style.traffic_content_block__title}>
            {t('price.leftBlockTitle')}
          </h3>
          <div className={style.price}>
            <div className={style.price_cost}>
              <span>9,99€</span>
              <span> / {t('price.leftBlockMonth')}</span>
            </div>
            <span className={style.price_rule}>
              {t('price.leftBlockRules')}
            </span>
          </div>
          <CustomLink
            href='/registration'
            target={false}
            style={style.link_yellow}
            text='price.leftBlockButton'
          />
        </div>
        <div className={classNames(style.traffic_content_block, style.right)}>
          <h3 className={style.traffic_content_block__title}>
            {t('price.rightBlockTitle')}
          </h3>
          <div className={style.cards}>
            <div className={classNames(style.cards_element, style.block_image)}>
              <Card
                icon={logo}
                alt='logo'
                text=''
                otherStyleImage={style.eateryClub}
              />
            </div>
            <div className={classNames(style.cards_element, style.block_text)}>
              <Card
                icon=''
                alt=''
                text={t('price.rigthBlockDescribe')}
                otherStyleText={style.eateryClub_text}
              />
            </div>
          </div>
          <CustomLink
            href='https://eatery.club/'
            target={false}
            style={style.link_blue}
            text='price.rigthBlockButton'
          />
        </div>
      </div>
      <div className={style.traffic_offer}>
        <h3 className={style.traffic_offer_title}>
          {t('price.specialOfferTitle')}
        </h3>
        <p className={style.traffic_offer_text}>
          {t('price.specialOfferDescribe')}
        </p>
      </div>
    </section>
  );
};

export default TrafficPlan;
